import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Layout from "../components/layout";
import SectionFooter from '../components/sections/section-footer/SectionFooter';
import Explore from '../utils/explore';
import { Link } from "gatsby";

import {theme} from '../utils/theme';


const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.body};
  height: 100%;
`

const Banner = styled.div`
  width: 83%;
  margin-left: 17%;
  height: 250px;
  background-color: ${props => props.theme.colors.dark};
  padding: 50px 100px;

  @media ${props => props.theme.breaks.down('md')} { 
    width: 100%;
    margin-left: 0;
    height: 200px;
    padding: 100px 40px 0 40px;
  }
`

const MainTitle = styled.h1`
  font-weight: 300;
  font-size: 7rem;
  color: ${props => props.theme.colors.primary};
  width: 450px;

  @media ${props => props.theme.breaks.down('md')} { 
    font-size: 3.5rem;
    width: 100%;
  }
`

const Caption = styled.h3`
  font-weight: 300;
  color: ${props => props.theme.colors.dark};
  font-size: 3rem;
  width: 87%;
  margin-bottom: 50px;

  @media ${props => props.theme.breaks.down('md')} { 
    font-size: 2rem;
  }
`

const MainContent = styled.div`
  width: 53%;
  margin: 50px auto;

  @media ${props => props.theme.breaks.down('md')} { 
    width: 80%;
  }
`

const Accordion = styled.div`
  .sub-container {
    height: ${props => props.tog ? '100%' : '0'};
    overflow: hidden;
  }
`

const ServiceBlocks = styled.div`
  display: flex;
  align-items: bottom;
  margin-bottom: 20px;
  cursor: pointer;

  .service {
    font-size: 5rem;
    color: ${props => props.theme.colors.primary};
    width: 95%;

    @media ${props => props.theme.breaks.down('md')} { 
      font-size: 3rem;
      margin-top: 20px;
    }

    @media ${props => props.theme.breaks.down('sm')} {
      width: 90%;
    }
  }

  .icon {
    width: 5%;
    padding-top: 10px;
  }

  /* &:last-child {
    margin-bottom: 100px;
    padding-bottom: 100px;
  } */
`

const ToggleButton = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.dark};
  color: ${props => props.theme.colors.primary};
  font-size: 2rem;
  text-align: center;
  padding: 8px 0;
  cursor: pointer;
  

  @media ${props => props.theme.breaks.down('sm')} {
    height: 35px;
    width: 35px;
    font-size: 1.8rem;
    position: relative;
    top: 10px;
  }
`

const Hr = styled.hr`
  margin: 20px 0 30px 0;
  border: 1px solid ${props => props.theme.colors.dark};
`

const ServiceText = styled.ul`
  margin-bottom: 30px;
  list-style-type: circle;
  list-style-position: inside;
  color: ${props => props.theme.colors.dark};
  padding-left: 3px;

  li {
    font-size: 30px;
    vertical-align: middle;
  }

  li span{
  font-weight: 300;
  line-height: 1.3;
  /* padding-top: -5px; */
  vertical-align: middle;
  /* display: inline-block; */
  margin-top: -6px;
  }
`


const FixedFooter = styled.div`
  height: 100px;
  width: 100%;
  /* min-width: ${props => props.theme.maxWidthMd};
  max-width: 1400px; */
  margin: 0 auto;
  padding-left: 40px;
  position: relative;
  background-color: ${props => props.theme.colors.body};

  @media ${props => props.theme.breaks.down('md')} { 
    padding-left: 0;
  }
`



const ServicesPage = ({ data }) => {

  useEffect(() => {
    const svgs = document.getElementsByClassName('st0');
    const burger = document.getElementsByClassName('nav_icon'); 

    console.log(svgs, burger);
    for (let i = 0; i < svgs.length; i++) {
      svgs[i].style.fill = theme.colors.body;
    }

    for (let i = 0; i < burger.length; i++) {
      burger[i].style.backgroundColor = theme.colors.body;
    }

    // return () => {
    //   for (let i = 0; i < svgs.length; i++) {
    //     svgs[i].style.fill = theme.colors.dark;
    //   }
    // }
  }, [])

  const [accordions, setAccordions] = useState({acc1: false, acc2: false,
      acc3: false, acc4: false, acc5: false});

  const toggleAccordion = (count) => {
    setAccordions(prev => ({...prev, [`acc${count}`]:  !prev[`acc${count}`]}))
  }

  const listItems = {
    'Brand Positioning': [
      {name: 'Competitive Research', s: false},
      {name: 'Target Audience Development', s: false},
      {name: 'Vision Statement', s: false},
      {name: 'Elevator Pitch', s: false},
      {name: 'Mission Statement', s: false},
      {name: 'Naming', s: false},
      {name: 'Know why you’re the only one who does what you do.', s: true}
    ],
    'Graphic Design': [
      {name: 'Logos', s: false},
      {name: 'Color Palette', s: false},
      {name: 'Typography', s: false},
      {name: 'Pattern Design', s: false},
      {name: 'Headshot Illustrations', s: false},
      {name: 'Trade Show Booths', s: false},
      {name: 'Pitch Decks', s: false},
      {name: 'Document Templates', s: false},
      {name: 'Mascot Creation and Design', s: false},
      {name: 'Look good. Feel good.', s: true},
    ],
    'Copy Writing': [
      {name: 'Slogans', s: false},
      {name: 'Taglines', s: false},
      {name: 'Ad Copy', s: false},
      {name: 'Web Copy', s: false},
      {name: 'Blog Posts', s: false},
      {name: 'Email Campaigns', s: false},
      {name: 'Copy Guides', s: false},
      {name: 'Give your brand a voice.', s: true}
    ],
    'Print Materials': [
      {name: 'Stationery', s: false},
      {name: 'Business Cards', s: false},
      {name: 'Pamphlets and Brochures', s: false},
      {name: 'Packaging', s: false},
      {name: 'Signage', s: false},
      {name: 'Calendars', s: false},
      {name: 'Labels', s: false},
      {name: 'See your brand. Feel your brand.', s: true}
    ],
    'Digital Branding': [
      {name: 'Website Design', s: false},
      {name: 'Product Naming', s: false},
      {name: 'Product Descriptions', s: false},
      {name: 'Chat Bots', s: false},
      {name: 'Social Media', s: false},
      {name: 'Video Scripting and Production', s: false},
      {name: 'Expand your presence.', s: true}
    ],
    'Marketing Materials': [
      {name: 'Web Banners', s: false},
      {name: 'Social Media Ads', s: false},
      {name: 'Flyers', s: false},
      {name: 'Posters', s: false},
      {name: 'Billboards', s: false},
      {name: 'Signage', s: false},
      {name: 'Stickers and Bumper Magnets', s: false},
      {name: 'Vehicle Wrapping', s: false},
      {name: 'Promotional Products and Giveaways', s: false},
      {name: 'Go beyond word-of-mouth.', s: true}
    ],
    'The Celebrand Touch': [
      {name: 'Personalized branding to meet your needs', s: false},
      {name: 'Detailed questionnaire process', s: false},
      {name: 'Continued service and brand management', s: false},
      {name: 'Research-based strategy', s: false},
      {name: 'Comprehensive brand book featuring style and copy guide', s: false},
      {name: 'Doing what’s best for your clients', s: false},
      {name: 'That little touch of magic that makes every piece of your branding special', s: false},
      {name: 'Get branding. Find understanding.', s: true}
    ],
}

  return (
    
      <Layout pageTitle="List of services we do">
      <Container>
        <Banner><MainTitle>Wait, what <br /> can you do?</MainTitle></Banner>

          <MainContent>
            <Caption>Don’t read this list as a set of limitations. Find inspiration for our next project for you.</Caption>

            <Accordion tog={accordions.acc1}>
              <ServiceBlocks onClick={() => toggleAccordion(1)}><div className="service">Brand Positioning</ div><div className="icon"><ToggleButton >{accordions.acc1 ? '-' : '+'}</ToggleButton></div></ServiceBlocks>
              <div className="sub-container">
                <Hr />
                <ServiceText>
                  {listItems['Brand Positioning'].map((cur) => <li key={cur.name}><span>{cur.s ? <strong>{cur.name}</strong> : cur.name}</span></li>)}
                </ServiceText>
                <Link to="/service-form"><Explore text="Contact" sec="services" sub="1" show></Explore></Link>
              </div>
            </Accordion>

            <Accordion tog={accordions.acc2}>
              <ServiceBlocks onClick={() => toggleAccordion(2)}><div className="service">Graphic Design</div><div className="icon"><ToggleButton>{accordions.acc2 ? '-' : '+'}</ToggleButton></div></ServiceBlocks>
              <div className="sub-container">
                <Hr />
                <ServiceText>
                {listItems['Graphic Design'].map((cur) => <li key={cur.name}><span>{cur.s ? <strong>{cur.name}</strong> : cur.name}</span></li>)}
                </ServiceText>
                <Link to="/service-form"><Explore text="Contact" sec="services" sub="2" show></Explore></Link>
              </div>
            </Accordion>

            <Accordion tog={accordions.acc3}>
              <ServiceBlocks onClick={() => toggleAccordion(3)}><div className="service">Copy Writing</div><div className="icon"><ToggleButton>{accordions.acc3 ? '-' : '+'}</ToggleButton></div></ServiceBlocks>
              <div className="sub-container">
                <Hr />
                <ServiceText>
                {listItems['Copy Writing'].map((cur) => <li key={cur.name}><span>{cur.s ? <strong>{cur.name}</strong> : cur.name}</span></li>)}
                </ServiceText>
                <Link to="/service-form"><Explore text="Contact" sec="services" sub="3" show></Explore></Link>
              </div>
            </Accordion>

            <Accordion tog={accordions.acc4}>
              <ServiceBlocks onClick={() => toggleAccordion(4)}><div className="service">Print Materials</div><div className="icon"><ToggleButton>{accordions.acc4 ? '-' : '+'}</ToggleButton></div></ServiceBlocks>
              <div className="sub-container">
                <Hr />
                <ServiceText>
                {listItems['Print Materials'].map((cur) => <li key={cur.name}><span>{cur.s ? <strong>{cur.name}</strong> : cur.name}</span></li>)}
                </ServiceText>
                <Link to="/service-form"><Explore text="Contact" sec="services" sub="4" show></Explore></Link>
              </div>
            </Accordion>

            <Accordion tog={accordions.acc5}>
              <ServiceBlocks onClick={() => toggleAccordion(5)}><div className="service">Digital Branding</div><div className="icon"><ToggleButton>{accordions.acc5 ? '-' : '+'}</ToggleButton></div></ServiceBlocks>
              <div className="sub-container">
                <Hr />
                <ServiceText>
                {listItems['Digital Branding'].map((cur) => <li key={cur.name}><span>{cur.s ? <strong>{cur.name}</strong> : cur.name}</span></li>)}
                </ServiceText>
                <Link to="/service-form"><Explore text="Contact" sec="services" sub="5" show></Explore></Link>
              </div>
            </Accordion>

            <Accordion tog={accordions.acc6}>
              <ServiceBlocks onClick={() => toggleAccordion(6)}><div className="service">Marketing Materials</div><div className="icon"><ToggleButton>{accordions.acc6 ? '-' : '+'}</ToggleButton></div></ServiceBlocks>
              <div className="sub-container">
                <Hr />
                <ServiceText>
                {listItems['Marketing Materials'].map((cur) => <li key={cur.name}><span>{cur.s ? <strong>{cur.name}</strong> : cur.name}</span></li>)}
                </ServiceText>
                <Link to="/service-form"><Explore text="Contact" sec="services" sub="6" show></Explore></Link>
              </div>
            </Accordion>

            <Accordion tog={accordions.acc7}>
              <ServiceBlocks onClick={() => toggleAccordion(7)}><div className="service">The Celebrand Touch</div><div className="icon"><ToggleButton>{accordions.acc7 ? '-' : '+'}</ToggleButton></div></ServiceBlocks>
              <div className="sub-container">
                <Hr />
                <ServiceText>
                {listItems['The Celebrand Touch'].map((cur) => <li key={cur.name}><span>{cur.s ? <strong>{cur.name}</strong> : cur.name}</span></li>)}
                </ServiceText>
                <Link to="/service-form"><Explore text="Contact" sec="services" sub="7" show></Explore></Link>
              </div>
            </Accordion>
            
          </MainContent>

         <FixedFooter>
          <SectionFooter></SectionFooter>
          
         </FixedFooter>
        
      </Container>
      </Layout>
    
)
}

export default ServicesPage;